import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["statusBar"];
  static values = {
    maxBounds: { type: Array, default: null },
    centrePoint: { type: Array, default: null },
    siteSize: { type: Number, default: null },
    superAdmin: { type: Boolean, default: null },
    userToken: { type: String, default: null },
    siteId: { type: Number, default: null },
    mapLoaded: { type: Boolean, default: false },
    public: { type: Boolean, default: false },
    hideUnrated: { type: Boolean, default: false },
    disableZoom: { type: Boolean, default: null },
    pinImageUrl: { type: String, default: null },
    pinClusterImageUrl: { type: String, default: null },
  };

  connect() {
    this.lastSelectedRouteColors = [];
    this.tile_params = "";
    this.initColours();
    this.initMap();
    window.map.on("load", () => {
      this.applyColours();
    });

    const fullScreen = document.getElementById("js-full-screen");
    fullScreen &&
      fullScreen.addEventListener("click", () => {
        setTimeout(() => {
          window.map.resize();
        }, 50);
      });

    const mapZoomIn = document.getElementById("js-map-zoom-in");
    mapZoomIn &&
      mapZoomIn.addEventListener("click", () => {
        window.map.zoomIn();
      });

    const mapZoomOut = document.getElementById("js-map-zoom-out");
    mapZoomOut &&
      mapZoomOut.addEventListener("click", () => {
        window.map.zoomOut();
      });

    const getCurrentLocation = document.getElementById("js-get-location");
    getCurrentLocation &&
      getCurrentLocation.addEventListener("click", () => {
        this.getCurrentLocation();
      });
  }

  initMap() {
    this.line_width = 4;
    this.line_scale = "none";
    this.defaultZoom = 5;

    this.setBaseUrl();
    let params = new URLSearchParams(document.location.search);
    window.mapType = params.get("mapType") || "avg";
    window.mapLoaded = false;

    mapboxgl.accessToken =
      "pk.eyJ1IjoiY29ycmVjdGVkdGltZSIsImEiOiJZQWpHMy1JIn0.wtBkQp-NkyRbRdcot5o-9g";
    window.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/light-v11",
      center: this.center(),
      bounds: this.bounds(),
      maxBounds: this.maxBounds(),
      // maxZoom: 24,
      // minZoom: 0,
      // zoom: 13, // starting zoom
      // transformRequest: (url, resourceType) => {
      //   if (resourceType === "Tile" && url.indexOf("http://localhost") > -1) {
      //     return {
      //       url: url.replace("http", "http"),
      //       headers: { Authorization: "Bearer 1234567890" },
      //     };
      //   }
      // },
    });

    window.map.on("load", () => {
      window.mapLoadedValue = true;
      this.hideLoader();
      let params = new URLSearchParams(document.location.search);
      this.initComfortMap(params);
      this.drawBounds();
      this.setInitialLineThickness(3);
      let initialTileParams = this.generateTileParams();
      this.updateTileParams(initialTileParams);

      // for transport planners with 1 site
      if (this.siteSizeValue === 1 && !this.superAdminValue) {
        window.map.setZoom(14);
        // for transport planners with 2 or more sites
      } else if (this.siteSizeValue > 1 && !this.superAdminValue) {
        window.map.setZoom(8);
        // for super admins
      } else {
        window.map.setZoom(14);
      }

      // disable zoom and drag on rmr
      if (this.disableZoomValue) {
        window.map.scrollZoom.disable();
        window.map.dragPan.disable();
        window.map.setZoom(12);
        this.setLineThickness(2);
      }

      // update text on zoom or drag
      // const updateTextVisibility = () => {
      //   const zoomLevel = window.map.getZoom();
      //   const textElements = document.querySelectorAll(
      //     ".mapping-areas__first-text, .mapping-areas__second-text"
      //   );
      //   const contentDrawer = document.querySelectorAll(
      //     ".public-map-filter-container__info"
      //   );
      //   const features = window.map.queryRenderedFeatures({
      //     layers: ["ltr--comfort_avg_layer"],
      //   });
      //
      //   setTimeout(() => {
      //     if (window.innerWidth > 700) {
      //       if (zoomLevel >= 12) {
      //         textElements.forEach((element) => {
      //           if (features.length === 0) {
      //             if (element.classList.contains("mapping-areas__first-text")) {
      //               element.style.display = "none";
      //             } else if (
      //               element.classList.contains("mapping-areas__second-text")
      //             ) {
      //               element.style.display = "block";
      //               contentDrawer.forEach((el) => {
      //                 el.style.height = "";
      //                 el.style.overflow = "";
      //               });
      //             }
      //           } else {
      //             if (element.classList.contains("mapping-areas__first-text")) {
      //               element.style.display = "block";
      //             } else if (
      //               element.classList.contains("mapping-areas__second-text")
      //             ) {
      //               element.style.display = "none";
      //             }
      //           }
      //         });
      //       }
      //     }
      //   }, 500);
      // };

      // window.map.on("dragend", updateTextVisibility);
      // window.map.on("zoom", updateTextVisibility);

      // block map zoom
      window.map.on("zoom", () => {
        const zoomWarnElement = document.querySelector(
          ".public-map-filter-container__zoom-warn"
        );
        const contentDrawer = document.querySelectorAll(
          ".public-map-filter-container__info"
        );
        const zoomLevel = window.map.getZoom();
        if (zoomLevel < 12) {
          zoomWarnElement.style.display = "block";
        } else {
          zoomWarnElement.style.display = "none";
        }

        if (zoomLevel > 14 && this.publicValue == true) {
          window.map.setMaxZoom(14);
        }
      });
    });

    window.map.on("idle", () => {
      if (!window.mapLoadedValue) {
        this.showLoader();
      } else {
        this.hideLoader();
      }

      // Set bounds used by export by the current map view
      let bounds = window.map.getBounds();
      let mapElement = document.getElementById("map-container");
      // (top, left, right, bottom)
      mapElement.dataset.bounds = [
        bounds.getNorth(),
        bounds.getWest(),
        bounds.getEast(),
        bounds.getSouth(),
      ];
    });

    window.map.on("sourcedata", (event) => {
      if (!event.isSourceLoaded) {
        this.showLoader();
      }
    });
  }

  initColours() {
    this.unrated_colour = "#7D7B80";
    this.stressed_colour = "#D1504C";
    this.uncomfortable_colour = "#E2B154";
    this.comfortable_colour = "#92c83e";
    this.enjoyable_colour = "#00AA4F";

    this.greyColour = "#7D7B80";
    this.clearColour = "rgba(255,0,255,0)";
  }

  applyColours() {
    // this.applySelectedRouteColors(this.lastSelectedRouteColors);
    const selectedPalette = document.cookie
      .split("; ")
      .find((row) => row.startsWith("selectedPalette="));
    if (selectedPalette) {
      const paletteName = selectedPalette.split("=")[1];
      this.setPalette(paletteName);
    }
    this.setPreviewColors();

    window.map.setPaintProperty(
      "ltr--comfort_avg_layer",
      "line-color",
      this.lineColours()
    );
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          window.map.setCenter([longitude, latitude]);
          window.map.setZoom(14);
        },
        (error) => {
          console.error("Error obtaining location:", error);
          alert(
            "Unable to retrieve your location. Please enable location services."
          );
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  }

  generateTileParams() {
    this.tile_params = this.buildTileParams(
      "?privacy_filter=1&minimum_user_threshold=2"
    );

    return this.tile_params;
  }

  showLoader() {
    const mapContainer = document.getElementById("map-overlay-loader");
    const mapContainerMapbox = document.querySelector(".mapboxgl-map");
    if (mapContainer) {
      mapContainer.classList.add("map-container__insert--loading");
      mapContainerMapbox.style.opacity = "0.3";
      mapContainer.style.zIndex = "1";
    }
    const loader = document.getElementById("comfortmap__loading-container");
    if (loader) {
      loader.style.display = "block";
    }
  }

  hideLoader() {
    const mapContainer = document.getElementById("map-overlay-loader");
    const mapContainerMapbox = document.querySelector(".mapboxgl-map");
    if (mapContainer) {
      mapContainer.classList.remove("map-container__insert--loading");
      mapContainerMapbox.style.opacity = "1";
      mapContainer.style.zIndex = "-1";
    }
    const loader = document.getElementById("comfortmap__loading-container");
    if (loader) {
      loader.style.display = "none";
    }
  }

  setInitialLineThickness(thickness) {
    if (window.map.getLayer("ltr--comfort_avg_layer")) {
      window.map.setPaintProperty(
        "ltr--comfort_avg_layer",
        "line-width",
        this.lineWidth(thickness)
      );
    }
  }

  resizeMap() {
    if (window.map) {
      window.map.resize();
    }
  }

  bounds() {
    if (this.hasMaxBoundsValue && this.maxBoundsValue.length > 0) {
      return mapboxgl.LngLatBounds.convert(this.maxBoundsValue);
    } else {
      return undefined;
    }
  }

  maxBounds() {
    if (this.hasMaxBoundsValue && this.maxBoundsValue.length > 0) {
      return mapboxgl.LngLatBounds.convert(this.maxBoundsValue);
    } else {
      return undefined;
    }
  }

  center() {
    if (
      this.hasCentrePointValue &&
      this.centrePointValue.length > 0 &&
      this.centrePointValue[0] != 0 &&
      this.centrePointValue[1] != 0
    ) {
      return new mapboxgl.LngLat(
        this.centrePointValue[0],
        this.centrePointValue[1]
      );
    } else {
      return undefined;
    }
  }

  resetFilterParams() {
    const originalParams = new URLSearchParams(this.tile_params);

    // These get re-applied if populated, but not reset if cleared.
    originalParams.delete("timeframe");
    originalParams.delete("purpose");
    originalParams.delete("frequency");
    originalParams.delete("work_travel");
    originalParams.delete("gender");
    originalParams.delete("ethnicity");

    return originalParams;
  }

  buildTileParams(tile_params) {
    // persist previous values
    const originalParams = this.resetFilterParams()
    const newTileParams = new URLSearchParams(tile_params);
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (urlSearchParams.get("personal")) {
      originalParams.set("personal", urlSearchParams.get("personal"));
    } else if (
      urlSearchParams.get("public") ||
      (this.hasPublicValue && this.publicValue == true)
    ) {
      originalParams.set("public", urlSearchParams.get("public") || true);
    }

    if (urlSearchParams.get("log_level")) {
      originalParams.set("log_level", urlSearchParams.get("log_level"));
    }

    if (urlSearchParams.get("max_age")) {
      originalParams.set("max_age", urlSearchParams.get("max_age"));
    }

    if (urlSearchParams.get("cache_bust")) {
      originalParams.set("cache_bust", urlSearchParams.get("cache_bust"));
    }

    if (this.hasHideUnratedValue && this.hideUnratedValue == true) {
      originalParams.set("hide_unrated", this.hideUnratedValue);
    } else {
      originalParams.delete("hide_unrated");
    }

    originalParams.set("precision", originalParams.get("precision") || 1);

    if (!urlSearchParams.get("public")) {
      originalParams.set("token", this.userTokenValue);
      originalParams.set("site_id", this.siteIdValue);
    }

    // console.log("Original Params: " + originalParams.toString());

    newTileParams.forEach(function (value, key) {
      // console.log("Setting " + key + " to " + value);
      originalParams.set(key, value);
    });

    // console.log("Original Params updated: " + originalParams.toString());

    return "?" + originalParams.toString();
  }

  updateTileParams(tile_params) {
    if (!window.map || typeof window.map.getSource !== "function") {
      console.error("Map is not initialized.");
      return;
    }

    // Ensure security requirements can be met
    this.tile_params = this.buildTileParams(tile_params);

    let firstSymbolId = this.getFirstSymbolId();
    // window.map.removeSource("comfort_avg")
    // let features = window.map.queryRenderedFeatures({layers: ['comfort_avg']})
    window.map.removeLayer("ltr--comfort_avg_layer");
    this.insertLayer(
      "comfort_avg",
      this.tile_params,
      firstSymbolId,
      this.line_width
    );

    if (this.element.dataset.controller.includes("maps--pin-map-layer")) {
      this.togglePins(true);
    }
  }

  // Function to fly to a location and then zoom down
  flyAndZoom(targetZoom) {
    if (window.map) {
      window.map.setZoom(targetZoom);
    }
  }

  setLocation({ detail }) {
    const {
      lat,
      lon,
      tile_params = {},
      line_thickness,
      hide_unrated,
      hide_status_bar,
      line_scale,
      palette,
      base_map,
      show_hide_routes,
      show_hide_rated_routes,
      selected_route_colors,
      hide_routes_show_gray,
      show_hide_pins,
    } = detail;

    // Set map location and zoom level
    if (lat && lon) {
      this.setMapLocation(lat, lon);
      return;
    }

    // Adjust line thickness
    if (line_thickness) {
      this.setLineThickness(line_thickness);
      return;
    }

    // Adjust line scale if provided
    if (line_scale) {
      this.setLineScale(line_scale);
      return;
    }

    if (palette) {
      this.setPalette(palette);
      return;
    }

    // Toggle unrated color visibility
    if (hide_unrated !== undefined) {
      this.toggleUnratedVisibility(hide_unrated);
      return;
    }

    // Toggle unrated color visibility
    if (hide_routes_show_gray !== undefined) {
      this.hideRoutesShowGray();
      return;
    }

    // Toggle status bar visibility
    if (hide_status_bar !== undefined) {
      this.toggleStatusBar(hide_status_bar);
      return;
    }

    // Set base map style
    if (base_map) {
      this.setBaseMapStyle(base_map);
      return;
    }

    // Toggle route visibility or apply dynamic color selection
    if (show_hide_routes !== undefined) {
      this.toggleRouteVisibility(show_hide_routes);
      return;
    }

    if (selected_route_colors) {
      this.applySelectedRouteColors(selected_route_colors);
      return;
    }

    if (show_hide_rated_routes !== undefined) {
      this.toggleRatedRoutes(show_hide_rated_routes);
      return;
    }

    if (show_hide_pins !== undefined) {
      this.togglePins(show_hide_pins);
      return;
    }

    // console.log("tile_params", tile_params);

    // Fallback to updating tile parameters
    this.updateTileParams(tile_params);
  }

  setMapLocation(lat, lon) {
    if (this.siteSizeValue > 1 && !this.superAdminValue) {
      window.map.setCenter([lon, lat]);
      window.map.setZoom(14);
    } else {
      window.map.flyTo({ center: [lon, lat] });
    }
  }

  setLineThickness(line_thickness) {
    this.line_width = parseInt(line_thickness);
    window.map.setPaintProperty(
      "ltr--comfort_avg_layer",
      "line-width",
      this.lineWidth(this.line_width)
    );
  }

  setLineScale(line_scale) {
    this.line_scale = line_scale;
    window.map.setPaintProperty(
      "ltr--comfort_avg_layer",
      "line-width",
      this.lineWidth(this.line_width)
    );
  }

  setPalette(paletteName) {
    document.cookie = `selectedPalette=${paletteName}; path=/; max-age=${
      60 * 60 * 24 * 30
    }`; // Expires in 30 days

    const palette = this.fetchPalette(paletteName);

    // Set colours for use in map
    this.unrated_colour = palette.unrated_colour;
    this.stressed_colour = palette.stressed_colour;
    this.uncomfortable_colour = palette.uncomfortable_colour;
    this.comfortable_colour = palette.comfortable_colour;
    this.enjoyable_colour = palette.enjoyable_colour;

    // Update the map
    if (this.lastSelectedRouteColors.length > 0) {
      this.applySelectedRouteColors(this.lastSelectedRouteColors);
    } else {
      window.map.setPaintProperty(
        "ltr--comfort_avg_layer",
        "line-color",
        this.lineColours()
      );
    }

    // Update the colours in the CSS
    this.updateComfortColor(
      [".unrated", ".rating-0", ".map-settings__feeling--unrated"],
      this.unrated_colour
    );
    this.updateComfortColor(
      [".stressed", ".rating-1", ".map-settings__feeling--stressed"],
      this.stressed_colour
    );
    this.updateComfortColor(
      [".uncomfortable", ".rating-2", ".map-settings__feeling--uncomfortable"],
      this.uncomfortable_colour
    );
    this.updateComfortColor(
      [".comfortable", ".rating-3", ".map-settings__feeling--comfortable"],
      this.comfortable_colour
    );
    this.updateComfortColor(
      [".enjoyable", ".rating-4", ".map-settings__feeling--enjoyable"],
      this.enjoyable_colour
    );

    document
      .getElementsByClassName(
        `comfort-map__select-div-palette-preview palette-${paletteName}`
      )[0]
      .classList.add("active");
  }

  fetchPalette(paletteName) {
    switch (paletteName) {
      case "paul_tol_muted":
        // Using Paul Tol's Muted
        return {
          unrated_colour: "#DDDDDD", // Gray for neutral/unrated
          stressed_colour: "#EE6677", // Coral red for stress
          uncomfortable_colour: "#AA3377", // Purple for discomfort
          comfortable_colour: "#66CCEE", // Cyan for comfort
          enjoyable_colour: "#44AA66", // Green for enjoyable
        };
        break;
      case "okabe_ito":
        // Using Okabe and Ito
        return {
          unrated_colour: "#000000", // Black for neutral/unrated
          stressed_colour: "#E69F00", // Orange for stress
          uncomfortable_colour: "#CC79A7", // Pink for discomfort
          comfortable_colour: "#0072B2", // Blue for comfort
          enjoyable_colour: "#009E73", // Teal for enjoyable
        };
        break;
      case "paul_tol_bright":
        // Using Paul Tol's Bright
        return {
          unrated_colour: "#DDDDDD", // Gray for neutral/unrated
          stressed_colour: "#EE6677", // Red for stress
          uncomfortable_colour: "#CCBB44", // Yellow for discomfort
          comfortable_colour: "#4477AA", // Blue for comfort
          enjoyable_colour: "#228833", // Green for enjoyable
        };
        break;
      case "ibm_design":
        // Using IBM Design Library
        return {
          unrated_colour: "#785EF0", // Purple for neutral/unrated (no gray available)
          stressed_colour: "#FE6100", // Orange for stress
          uncomfortable_colour: "#FFB000", // Yellow for discomfort
          comfortable_colour: "#648FFF", // Blue for comfort
          enjoyable_colour: "#DC267F", // Pink for enjoyable
        };
        break;
      default: // normal
        return {
          unrated_colour: "#7D7B80",
          stressed_colour: "#D1504C",
          uncomfortable_colour: "#E2B154",
          comfortable_colour: "#92c83e",
          enjoyable_colour: "#00AA4F",
        };
    }
  }

  setPreviewColors() {
    [
      "normal",
      "paul_tol_muted",
      "okabe_ito",
      "paul_tol_bright",
      "ibm_design",
    ].forEach((paletteName) => {
      if (
        !document.querySelector(`.palette-preview--${paletteName}-stressed`)
      ) {
        return; // stop processing this iteration, elements are not found
      }

      const palette = this.fetchPalette(paletteName);
      document.querySelector(
        `.palette-preview--${paletteName}-stressed`
      ).style.backgroundColor = palette.stressed_colour;
      document.querySelector(
        `.palette-preview--${paletteName}-uncomfortable`
      ).style.backgroundColor = palette.uncomfortable_colour;
      document.querySelector(
        `.palette-preview--${paletteName}-comfortable`
      ).style.backgroundColor = palette.comfortable_colour;
      document.querySelector(
        `.palette-preview--${paletteName}-enjoyable`
      ).style.backgroundColor = palette.enjoyable_colour;
      document.querySelector(
        `.palette-preview--${paletteName}-unrated`
      ).style.backgroundColor = palette.unrated_colour;
    });
  }

  updateComfortColor(classNames, color) {
    for (const sheet of document.styleSheets) {
      // Skip cross-origin stylesheets
      if (sheet.href && new URL(sheet.href).origin !== window.location.origin) {
        continue;
      }

      const rules = sheet.cssRules || sheet.rules;

      for (let i = 0; i < rules.length; i++) {
        if (classNames.includes(rules[i].selectorText)) {
          rules[i].style.backgroundColor = color;
          rules[i].style.color = color;
        }
      }
    }
  }

  toggleUnratedVisibility(hide_unrated) {
    this.unrated_colour = hide_unrated ? this.greyColour : this.clearColour;
    window.map.setPaintProperty(
      "ltr--comfort_avg_layer",
      "line-color",
      this.lineColours()
    );
  }

  hideRoutesShowGray() {
    window.map.setPaintProperty(
      "ltr--comfort_avg_layer",
      "line-color",
      this.lineColoursG()
    );
  }

  toggleStatusBar(hide_status_bar) {
    if (hide_status_bar) {
      this.statusBarTarget.classList.add("hidden");
    } else {
      this.statusBarTarget.classList.remove("hidden");
    }
  }

  setBaseMapStyle(base_map) {
    this.changeMapStyle(base_map);
    // const mapSprites = {
    //   streets: {sprite: "mapbox://styles/mapbox/light-v11", name: "MapBox Light"},
    //   satellite: {sprite: "mapbox://styles/mapbox/satellite-v9", name: "MapBox Satelite"},
    //   terrain: {sprite: "mapbox://styles/mapbox/traffic-day-v2", name: "MapBox Traffic Day"},
    //   default: {sprite: "mapbox://styles/mapbox/light-v11", name: "MapBox Light"}
    // };
    //
    // const prevStyle = map.getStyle();
    // map.setStyle({
    //   ...prevStyle,
    //   sources: {
    //     'mapbox-satellite': {
    //       type: 'raster',
    //       url: 'mapbox://mapbox.satellite',
    //       tileSize: 256
    //     },
    //     ...Object.entries(prevStyle.sources)
    //       .filter(([id]) => id.startsWith('ltr--'))
    //       .reduce((acc, [id, source]) => ({ ...acc, [id]: source }), {})
    //   },
    //   layers: [
    //     {
    //       id: 'satellite',
    //       type: 'raster',
    //       source: 'mapbox-satellite',
    //       minzoom: 0,
    //       maxzoom: 22
    //     },
    //     ...prevStyle.layers.filter(layer => layer.id.startsWith('ltr--'))
    //   ]
    // });
  }

  mapStyles() {
    return {
      light: {
        sprite: "mapbox://sprites/mapbox/light-v11",
        name: "MapBox Light",
        sources: {
          "mapbox-streets": {
            type: "vector",
            url: "mapbox://mapbox.mapbox-streets-v8",
          },
        },
        layers: [
          {
            id: "streets-base",
            type: "background",
            paint: { "background-color": "#ffffff" },
          },
        ],
      },
      satellite: {
        sprite: "mapbox://sprites/mapbox/satellite-v9",
        name: "MapBox Satelite",
        sources: {
          "mapbox-satellite": {
            type: "raster",
            url: "mapbox://mapbox.satellite",
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "satellite",
            type: "raster",
            source: "mapbox-satellite",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
    };
  }

  // Function to change style
  changeMapStyle(styleKey) {
    const style = this.mapStyles()[styleKey] ?? this.mapStyles("light");
    const prevStyle = map.getStyle();

    map.setStyle({
      ...prevStyle,
      sprite: prevStyle.sprite, // Keep original sprite to preserve symbols
      sources: {
        ...style.sources, // Add the new style's sources
        ...Object.entries(prevStyle.sources)
          .filter(([id]) => id.startsWith("ltr--"))
          .reduce((acc, [id, source]) => ({ ...acc, [id]: source }), {}),
      },
      layers: [
        ...style.layers, // Add the new style's layers first
        ...prevStyle.layers.filter((layer) => layer.id.startsWith("ltr--")),
      ],
    });
  }

  toggleRouteVisibility(show_hide_routes) {
    this.lastSelectedRouteColors = [];
    const color = show_hide_routes
      ? this.lineColours()
      : this.lineColoursGrey();
    window.map.setPaintProperty("ltr--comfort_avg_layer", "line-color", color);
  }

  applySelectedRouteColors(selected_route_colors) {
    this.lastSelectedRouteColors = selected_route_colors;
    window.map.setPaintProperty(
      "ltr--comfort_avg_layer",
      "line-color",
      this.lineColoursDynamicMulti(selected_route_colors)
    );
  }

  toggleRatedRoutes(show_hide_rated_routes) {
    if (show_hide_rated_routes) {
      window.map.setPaintProperty(
        "ltr--comfort_avg_layer",
        "line-color",
        this.lineColours()
      );
    }
  }

  togglePins(show_hide_pins) {
    if (show_hide_pins) {
      this.insertLayer("pins", this.tile_params, this.getFirstSymbolId(), 4);
    } else {
      window.map.removeLayer("ltr--pin_single_layer");
      window.map.removeLayer("ltr--pin_cluster_layer");
      window.map.removeLayer("ltr--pin_count");
    }
  }

  setBaseUrl() {
    if (
      document.getElementsByTagName("main")[0].classList.contains("development")
    ) {
      this.baseUrl = "http://localhost:3002";
    } else {
      this.baseUrl = "https://journey.lovetoride.net";
    }
  }

  initComfortMap(params) {
    let lat = "";
    let lon = "";
    // if tp2 or tp1
    if (this.siteSizeValue > 0 && !this.superAdminValue) {
      lat = params.get("lat");
      lon = params.get("lon");
    } else {
      // set to ltr bristol as default for super admins
      lat = params.get("lat") || 51.444229;
      lon = params.get("lon") || -2.563627;
    }

    if (lat != null && lon != null) {
      window.map.setCenter([parseFloat(lon), parseFloat(lat)]);
      window.map.setZoom(params.get("zoom") || this.defaultZoom);
    }

    let firstSymbolId = this.getFirstSymbolId();

    var max_age = params.get("max_age");
    if (max_age == null) {
      max_age = 3600;
    }
    var tile_params =
      "?max_age=" +
      max_age +
      "&cache_bust=" +
      params.get("cache_bust") +
      "&precision=1";

    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.get("personal")) {
      tile_params = tile_params + "&personal=true";
    } else if (urlSearchParams.get("public")) {
      tile_params = tile_params + "&public=true";
    }

    if (!urlSearchParams.get("public")) {
      tile_params =
        tile_params +
        "&token=" +
        this.userTokenValue +
        "&site_id=" +
        this.siteIdValue;
    }

    let debug_options = params.get("debug_trip") || "";
    if (debug_options.includes("hu")) {
      this.unrated_colour = this.clearColour;
    }

    const lineWidth = params.get("line_width") || "5";
    this.insertLayer(
      "comfort_avg",
      this.tile_params,
      firstSymbolId,
      parseInt(lineWidth)
    );

    window.map.on("mouseenter", "ltr--comfort_avg_layer", (e) => {
      this.updateStatusBar(e.lngLat, e.features[0].properties);
    });

    if (this.maxBounds() != undefined) {
      window.map.fitBounds(this.maxBounds());
      window.map.setMinZoom(0);
    } else if (this.center() != undefined) {
      window.map.jumpTo({ center: this.center() });
      window.map.setZoom(this.defaultZoom);
    }
  }

  drawBounds() {
    let bounds = this.maxBounds();
    if (bounds == undefined) {
      return;
    }

    window.map.addLayer({
      id: "ltr--line-bounding-box",
      type: "line",
      paint: {
        "line-color": "#666",
        "line-width": 3,
        "line-opacity": 0.9,
      },
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                bounds.getSouthWest().toArray(),
                bounds.getSouthEast().toArray(),
                bounds.getNorthEast().toArray(),
                bounds.getNorthWest().toArray(),
                bounds.getSouthWest().toArray(),
              ],
            ],
          },
        },
      },
    });
  }

  bracket_highest_value_to_text(value) {
    if (this.hasSuperAdminValue && this.superAdminValue) {
      return value;
    } else {
      // convert value to a text label
      let mapping = {
        0: "0",
        10: "1–10",
        20: "11–20",
        50: "21–50",
        100: "51–100",
        200: "101–200",
        500: "201–500",
        1000: "501–1000",
        2000: "1001-2000",
      };

      if (mapping[value] == undefined) {
        return value - 999 + "-" + value;
      }
      return mapping[value];
    }
  }

  updateStatusBar(lngLat, properties) {
    if (this.hasStatusBarTarget) {
      // console.log(properties);
      var html = "";
      if (this.hasSuperAdminValue && this.superAdminValue) {
        html += `${lngLat} - <a target="_blank" href="https://metabase.lovetoride.net/question/1123-segment-debug?segment_id=${properties.segment_id}">Segment ${properties.segment_id}</a>
         From <a target="_blank" href="https://www.openstreetmap.org/way/${properties.osm_id}">OSM ${properties.osm_id}</a>`;
      }
      html += `
         <span>Trips: ${this.bracket_highest_value_to_text(
           properties.trips_count
         )}</span>
         <span>Ratings: ${this.bracket_highest_value_to_text(
           properties.ratings_count
         )}</span>
         <span>Unique raters: ${this.bracket_highest_value_to_text(
           properties.unique_raters_count
         )}</span>
         <span>Unique riders: ${this.bracket_highest_value_to_text(
           properties.unique_riders_count
         )}</span>
  `;
      if (properties.rating_value != null) {
        html += `&nbsp;<span class="rating-${properties.rating_value}">Average rating value ${properties.rating_value}</span>`;
      }
      this.statusBarTarget.innerHTML = html;
    }
  }

  getFirstSymbolId() {
    for (const layer of window.map.getStyle().layers) {
      if (layer.type === "symbol") {
        return layer.id;
      }
    }
    return null; // Return null if no symbol layer is found
  }

  lineColours() {
    return [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "rating_value"]],
      0,
      this.unrated_colour,
      1,
      this.stressed_colour,
      2,
      this.uncomfortable_colour,
      3,
      this.comfortable_colour,
      4,
      this.enjoyable_colour,
    ];
  }

  lineColoursG() {
    return [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "rating_value"]],
      0,
      "#7D7B80",
      1,
      "#7D7B80",
      2,
      "#7D7B80",
      3,
      "#7D7B80",
      4,
      "#7D7B80",
    ];
  }

  lineColoursGrey(rating) {
    const ratingToColor = {
      unrated: this.unrated_colour,
    };

    const colors = new Array(5).fill(ratingToColor.unrated);

    Object.entries(ratingToColor).forEach(([key, color], index) => {
      if (rating === key) {
        colors[index] = color;
      }
    });

    return [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "rating_value"]],
      ...colors.flatMap((color, index) => [index, color]),
    ];
  }

  lineColoursDynamicMulti(selectedColors) {
    const ratingToColor = {
      unrated: this.unrated_colour,
      stressed: this.stressed_colour,
      uncomfortable: this.uncomfortable_colour,
      comfortable: this.comfortable_colour,
      enjoyable: this.enjoyable_colour,
    };

    const colors = new Array(5).fill(this.clearColour);

    selectedColors.forEach((color) => {
      const index = Object.keys(ratingToColor).indexOf(color);
      if (index !== -1) {
        colors[index] = ratingToColor[color];
      }
    });

    return [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "rating_value"]],
      ...colors.flatMap((color, index) => [index, color]),
    ];
  }

  interpolatedLineWidthBy(attributeName, baseLineWidth) {
    return [
      "interpolate",
      ["linear"],
      ["to-number", ["get", attributeName]],
      0,
      0.25 * baseLineWidth,
      100,
      5 * baseLineWidth,
      1000,
      10 * baseLineWidth,
    ];
  }

  lineWidth(baseLineWidth) {
    let result = null;
    switch (this.line_scale) {
      case "unique_riders_count":
        result = this.interpolatedLineWidthBy(this.line_scale, baseLineWidth);
        break;
      case "unique_raters_count":
        result = this.interpolatedLineWidthBy(this.line_scale, baseLineWidth);
        break;
      case "trips_count":
        result = this.interpolatedLineWidthBy(this.line_scale, baseLineWidth);
        break;
      case "ratings_count":
        result = this.interpolatedLineWidthBy(this.line_scale, baseLineWidth);
        break;
      default:
        result = this.line_width;
        break;
    }
    return result;
  }

  loadPinImages() {
    // Add single pins as symbols
    if (!window.map.hasImage("pin-icon")) {
      window.map.loadImage(this.pinImageUrlValue, (error, image) => {
        if (error) throw error;

        // Add image to map
        window.map.addImage("pin-icon", image);
      });
    }
    if (!window.map.hasImage("pin-cluster-icon")) {
      window.map.loadImage(this.pinClusterImageUrlValue, (error, image) => {
        if (error) throw error;

        // Add image to map
        window.map.addImage("pin-cluster-icon", image);
      });
    }
  }

  insertLayer(layer, tile_params, firstSymbolId, baseLineWidth) {
    if (layer === "comfort_avg") {
      if (window.map.getSource("ltr--comfort_avg")) {
        window.map
          .getSource("ltr--comfort_avg")
          .setTiles([
            `${this.baseUrl}/api/tiles/{z}/{x}/{y}.mvt${tile_params}&comfort=${window.mapType}`,
          ])
          .reload();
      } else {
        window.map.addSource("ltr--comfort_avg", {
          type: "vector",
          tiles: [
            `${this.baseUrl}/api/tiles/{z}/{x}/{y}.mvt${tile_params}&comfort=${window.mapType}`,
          ],
          minzoom: 12,
          maxzoom: 15,
        });
      }

      if (!window.map.getLayer("ltr--comfort_avg_layer")) {
        window.map.addLayer(
          {
            id: "ltr--comfort_avg_layer",
            type: "line",
            source: "ltr--comfort_avg",
            "source-layer": "default",
            layout: {
              "line-cap": "round",
              // "line-gap": 10,
              "line-join": "miter",
            },
            paint: {
              "line-color": this.lineColours(),
              // Adjust the heatmap
              "line-width": this.lineWidth(baseLineWidth),
            },
          },
          firstSymbolId
        );
      } else {
        // Optionally, update properties if the layer already exists
        window.map.setPaintProperty(
          "ltr--comfort_avg_layer",
          "line-color",
          "#92c83e"
        );
        window.map.setPaintProperty(
          "ltr--comfort_avg_layer",
          "line-width",
          this.lineWidth(baseLineWidth)
        );
      }
    }

    if (layer == "pins") {
      if (window.map.getSource("ltr--pins")) {
        window.map
          .getSource("ltr--pins")
          .setTiles([
            `${this.baseUrl}/api/pin_tiles/{z}/{x}/{y}.mvt${tile_params}`,
          ])
          .reload();
      } else {
        window.map.addSource("ltr--pins", {
          type: "vector",
          tiles: [
            `${this.baseUrl}/api/pin_tiles/{z}/{x}/{y}.mvt${tile_params}`,
          ],
          minzoom: 3,
          maxzoom: 15,
        });
      }
      this.loadPinImages();

      if (!window.map.getLayer("ltr--pin_cluster_layer")) {
        // window.map.showTileBoundaries = true;

        // Add symbol layer for single pins
        window.map.addLayer({
          id: "ltr--pin_single_layer",
          type: "symbol",
          source: "ltr--pins",
          "source-layer": "default",
          filter: ["==", ["get", "pin_count"], 1],
          layout: {
            "icon-image": "pin-icon",
            "icon-size": 0.25,
            "icon-allow-overlap": true,
            "icon-anchor": "bottom",
            "icon-opacity": 1.0,
          },
        });

        window.map.addLayer({
          id: "ltr--pin_cluster_layer",
          type: "symbol",
          source: "ltr--pins",
          "source-layer": "default",
          filter: [">", ["get", "pin_count"], 1],
          layout: {
            "icon-image": "pin-cluster-icon",
            "icon-size": 0.25,
            "icon-allow-overlap": true,
            "icon-anchor": "center",
            "icon-opacity": 1.0,
          },
          // 'paint': {
          //   // Size based on pin_count
          //   'circle-radius': [
          //     'case',
          //     ['==', ['get', 'pin_count'], 1],
          //     20,  // Single pin size
          //     ['interpolate', ['linear'], ['get', 'pin_count'],
          //       2, 30,    // Small cluster
          //       10, 35,  // Medium cluster
          //       50, 40   // Large cluster
          //     ]
          //   ],
          //   // Color based on whether it's a cluster or single pin
          //   'circle-color': [
          //     'case',
          //     ['==', ['get', 'pin_count'], 1],
          //     '#65C2CC',  // Single pin color
          //     '#38A5AF'   // Cluster color
          //   ],
          //   'circle-opacity': 1.0,
          //   'circle-blur': 0
          //   // 'circle-stroke-width': 2,
          //   // 'circle-stroke-color': '#ffffff'
          // }
        });

        window.map.addLayer({
          id: "ltr--pin_count",
          type: "symbol",
          source: "ltr--pins",
          "source-layer": "default",
          filter: [">", ["get", "pin_count"], 1], // Only show for clusters
          layout: {
            "text-field": ["get", "pin_count"],
            "text-size": 20,
            "text-weight": "bold",
          },
          paint: {
            "text-color": "#ffffff",
          },
        });
      }
    }
  }
}
