import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "modal",
    "tags",
    "feedback",
    "location",
    "timestamp",
    "photos",
    "prevButton",
    "nextButton",
    "pinIndex"
  ]

  static values = {
    pinDetailsUrl: {type: String, default: null},
    userToken: {type: String, default: null},
    siteId: {type: Number, default: null},
    timestampFormat: {type: String, default: null},
    closeButtonUrl: {type: String, default: null},
  }

  connect() {
    this.setBaseUrl()
    window.map.on('click', "ltr--pin_single_layer", (e) => {this.handleClick(e)})
    window.map.on('click', "ltr--pin_cluster_layer", (e) => {this.handleClick(e)})
  }

  handleClick(event) {
    // Map is loading, don't do anything
    if (document.getElementsByClassName('map-container__insert--loading').length > 0) {
      return
    }

    if (this.hasModalTarget) {
      if (this.popup) {
        this.popup.remove();
      }

      this.originalParams = new URLSearchParams();
      this.originalParams.set("token", this.userTokenValue);
      this.originalParams.set("site_id", this.siteIdValue);

      this.pinIds = JSON.parse(event.features[0].properties.pin_ids)
      this.currentPinIndex = 0
      this.featureLngLat = event.features[0].geometry.coordinates
      this.pinPosition = {x: event.originalEvent.layerX, y: event.originalEvent.layerY}
      this.fetchPinDetails()
    }
  }

  fetchPinDetails() {
    fetch(this.baseUrl + `/api/pins/${this.pinIds[this.currentPinIndex]}?${this.originalParams}`)
      .then(response => response.json())
      .then(json => {
        this.populatePinDetail(json)
        this.buildPopup()
      })
  }

  prevPin() {
    this.currentPinIndex -= 1
    // this.popup.remove();
    this.fetchPinDetails()
    if (this.currentPinIndex === 0) {
      this.prevButtonTarget.disabled = true
    }
    if (this.currentPinIndex < this.pinIds.length - 1) {
      this.nextButtonTarget.disabled = false
    }
  }

  nextPin() {
    this.currentPinIndex += 1
    // this.popup.remove();
    this.fetchPinDetails()
    if (this.currentPinIndex === this.pinIds.length - 1) {
      this.nextButtonTarget.disabled = true
    }
    if (this.currentPinIndex > 0) {
      this.prevButtonTarget.disabled = false
    }
  }

  overridePopupStructure() {
    this.popup._content.querySelector('.mapboxgl-popup-close-button').innerHTML = `<img src="${this.closeButtonUrlValue}">`
    if (this.pinIds.length > 1) {
      this.modalTarget.closest('.mapboxgl-popup').classList.remove('map-pin-modal-single')
    } else {
      this.modalTarget.closest('.mapboxgl-popup').classList.add('map-pin-modal-single')
    }
  }

  buildPopup() {
    if (!this.popup) {
      let content = this.modalTarget.cloneNode(true)
      content.classList.remove('hidden')
      let anchor = this.findPopupAnchor()

      this.popup = new mapboxgl.Popup({maxWidth: '400px', anchor: anchor})

      this.popup.setLngLat(this.featureLngLat)
        .setDOMContent(content)
        .addTo(window.map);
      this.popup.on('close', () => {
        this.popup = null
      });

      this.overridePopupStructure()
    }
  }

  findPopupAnchor() {
    let statusBar = document.querySelector('#map-container .status-bar')
    let height = window.map.getCanvasContainer().querySelector('canvas').scrollHeight - statusBar.scrollHeight;
    let width = window.map.getCanvasContainer().querySelector('canvas').scrollWidth;
    let aspectRatio = width / height;

    // Handle pins in corners
    if (this.pinPosition.x < width * 0.33 && this.pinPosition.y < height * 0.33) {
      return 'top-left';
    } else if (this.pinPosition.x > width * 0.66 && this.pinPosition.y < height * 0.33) {
      return 'top-right';
    } else if (this.pinPosition.x < width * 0.33 && this.pinPosition.y > height * 0.66) {
      return 'bottom-left';
    } else if (this.pinPosition.x > width * 0.66 && this.pinPosition.y > height * 0.66) {
      return 'bottom-right';
    } else if (aspectRatio > 1.33) { // 4:3 ratio
      // Handle pins on sides
      if (this.pinPosition.y > height * 0.66) {
        return 'bottom';
      } else if (this.pinPosition.y < height * 0.33) {
        return 'top';
      } else if (this.pinPosition.x > width * 0.5) {
        return 'right';
      } else {
        return 'left';
      }
    } else if (aspectRatio < 0.75) { // 3:4 ratio
      // Handle pins on sides
      if (this.pinPosition.x > width * 0.66) {
        return 'right';
      } else if (this.pinPosition.x < width * 0.33) {
        return 'left';
      } else if (this.pinPosition.y > height * 0.5) {
        return 'bottom';
      } else {
        return 'top';
      }
    } else {
      if (width > height) {
        if (this.pinPosition.x > width * 0.5) {
          return 'right';
        } else {
          return 'left';
        }
      } else {
        if (this.pinPosition.y > height * 0.5) {
          return 'bottom';
        } else {
          return 'top';
        }
      }
    }
  }

  populatePinDetail(data) {
    console.log(data)

    if (this.pinIds.length > 1) {
      if (this.currentPinIndex > 0) {
        this.prevButtonTarget.disabled = false
      }
      if (this.currentPinIndex < this.pinIds.length - 1) {
        this.nextButtonTarget.disabled = false
      }
      this.pinIndexTarget.innerHTML = `${this.currentPinIndex + 1} of ${this.pinIds.length}`
    } else {
      this.prevButtonTarget.disabled = true
      this.nextButtonTarget.disabled = true
      this.pinIndexTarget.innerHTML = "1 of 1"
    }

    if (data.feedback == "") {
      this.feedbackTarget.innerHTML = "No comment was shared."
    } else {
      this.feedbackTarget.innerHTML = data.feedback
    }
    if (data.tags.length > 0) {
      this.tagsTarget.innerHTML = data.tags.map(tag => `<li class="tag">${tag.description}</li>`).join(' ')
    } else {
      this.tagsTarget.innerHTML = '<li class="tag tags-na">None Selected</li>'
    }
    this.locationTarget.innerHTML = data.label
    let timestamp = data.reported_at || data.created_at
    this.timestampTarget.innerHTML = moment(timestamp).format(this.timestampFormatValue)
    if (data.photos !== undefined) {
      this.photosTarget.innerHTML = data.photos.map(photo => `<img src="${photo.url}" alt="${photo.alt_text}" class="photo" />`).join(' ')
    }
  }

  setBaseUrl() {
    if (
      document.getElementsByTagName("main")[0].classList.contains("development")
    ) {
      this.baseUrl = "http://localhost:3002";
    } else {
      this.baseUrl = "https://journey.lovetoride.net";
    }
  }

}